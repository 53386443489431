import Calendar from './components/Calendar';
import './App.css';
import Home from './components/Home.js';
import About from './components/About.js'
import Navbar from './components/Navbar.js'
import Footer from './components/Footer';
import joinSlide1 from './resources/join/join-1.png';
import joinSlide2 from './resources/join/join-2.png';
import joinSlide3 from './resources/join/join-3.png';
import joinSlide4 from './resources/join/join-4.png';
import joinSlide5 from './resources/join/join-5.png';
import Join from './components/Join.js';

function App() {
    const joinSlides = [
        joinSlide1, joinSlide2, joinSlide3, joinSlide4, joinSlide5
    ];

    const sliderContainerStyles = {
        width: '40rem',
        height: '40rem',
        margin: '10rem auto'
    }

    return (
        <div className="App">
            <Navbar />
            <header className="App-header">
                <Home />
            </header>            
            <About />
            <div style={sliderContainerStyles}>
                <Join slides={joinSlides} />
            </div>
            <Calendar />
            <Footer />
        </div>
        
    );
}

export default App;
