import Logo from '../logo.png'
import React from 'react';
import { useState, useEffect } from 'react';

function Navbar() {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
    }
    else {
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changeBackground);

  return (
    <nav className={navbar ? "navbar dark:bg-gray-900 fixed w-full z-20 top-0 left-0" : 
    "navbar bg-transparent fixed w-full z-20 top-0 left-0"}>
      <div class="flex flex-wrap items-center justify-between mx-auto p-4">
        <a onClick={(e) => scrollToHome(e)} class="flex items-center">
          <img src={Logo} class="h-8 mr-3" alt="Flowbite Logo" />
          <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Leiden Debating Union</span>
        </a>
        <div class="flex md:order-2">
          <button type="button" class="button text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0"><a href="https://forms.gle/sDHQegVDCRBv2jCR9">Become a member</a></button>
          <button data-collapse-toggle="navbar-sticky" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
        </div>
        <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
          <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0  bg-transparent dark:border-gray-700">
            <li>
              <a href='javascript:void(0)' onClick={(e) => scrollToAbout(e)} class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">About</a>
            </li>
            <li>
              <a href='javascript:void(0)' class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Join Us</a>
            </li>
            <li>
              <a href='javascript:void(0)' onClick={(e) => scrollToEvents(e)} class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Events</a>
            </li>
            <li>
              <a href='javascript:void(0)' onClick={(e) => scrollToContact(e)} class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );

}

const scrollToAbout = (e) => {
  e.preventDefault();
  const element = document.getElementById('about');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const scrollToEvents = (e) => {
  e.preventDefault();
  const element = document.getElementById('calendar');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const scrollToContact = (e) => {
  e.preventDefault();
  const element = document.getElementById('footer');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const scrollToHome = (e) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  });
};

export default Navbar;
