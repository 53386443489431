function Calendar() {
  return (
    <div className="calendar-container mt-16 mb-16">
      <iframe src="https://embed.styledcalendar.com/#94HpNaaEKaxdND9T81Tg" title="Event Calendar" class="calendar styled-calendar-container" data-cy="calendar-embed-iframe"></iframe>
      <script async type="module" src="https://embed.styledcalendar.com/assets/parent-window.js"></script>
    </div>
  );
}

export default Calendar;
