import { useState } from "react";
import './Join.css';

function Join ({slides}) {
    const [currentIndex, setCurrentIndex] = useState(0);

    const slidesStyle = {
        width: "100%",
        height: "100%",
        borderRadius: "10px", 
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage: `url(${slides[currentIndex]})`
    }

    const leftArrowStyles = {
        position: "absolute",
        top: "50%",
        transform: "translate(0, -50%)",
        left: "32px",
        fontSize: "45px",
        color: "#fff",
        zIndex: 1,
        cursor: "pointer"
    };

    const rightArrowStyles = {
        position: "absolute",
        top: "50%",
        transform: "translate(0, -50%)",
        right: "32px",
        fontSize: "45px",
        color: "#fff",
        zIndex: 1,
        cursor: "pointer"
    };

    const dotsContainerStyles = {
        display: 'flex',
        justifyContent: 'center'
    };

    const dotStyles = {
        margin: '0 3px',
        cursor: 'pointer',
        fontSize: '20px'
    }

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    }

    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.lenght - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const goToNext = () => {
        const isLastSlide = currentIndex === slides.lenght - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }

    return(
        <div className='h-full relative mt-10'>
            <div style={leftArrowStyles} onClick={goToPrevious}>&#11164;</div>
            <div style={rightArrowStyles} onClick={goToNext}>&#11166;</div>
            <div style={slidesStyle}></div>
            <div style={dotsContainerStyles}>
                {slides.map((slide, slideIndex) => (
                    <div style={dotStyles} key={slideIndex} onClick={() => goToSlide(slideIndex)}>&#9679;</div>
                ))}
            </div>
        </div>
    );
}

export default Join;